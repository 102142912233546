<template>
  <div class="coalition">
    <div class="coalition__container">
      <div class="coalition__content">
        <h1 class="coalition__header">La coalition marocaine pour la redevabilité</h1>
      </div>
      <div class="coalition__video-container">
        <div
          class="coalition__video-overlay coalition__video-overlay--hidden"
        ></div>
        <video
          @pause="pauseVideo"
          class="coalition__video"
          :poster="require('@/assets/images/thumbnail.png')"
        >
          <source
            src="https://etachawor.s3.eu-west-3.amazonaws.com/coalition.mp4"
            type="video/mp4"
          />
        </video>
        <atom-icon
          @click.prevent="playVideo"
          icon="play-mini"
          :fill="true"
          class="coalition__video-player"
        ></atom-icon>
      </div>
      <div class="coalition__copy">
        <h2>Les rôles de la coalitions sont:</h2>
        <ul>
          <li>
            Renforcer la participation de la société civile dans le suivi et
            l’évaluation des politiques publiques ;
          </li>
          <li>
            Suivre le processus d’élaboration et d’adoption du budget de
            l’Etat de l’année ;
          </li>
          <li>
            Evaluer la transparence budgétaire par cycle budgétaire conformément
            aux dispositions de la loi organique des finances 130-13 ;
          </li>
          <li>
            Suivre particulièrement l&#39;exécution des budgets du secteur de la
            santé et celui de l’éducation ;
          </li>
          <li>
            Collaborer avec les commissions parlementaires dans le but de
            renforcer la redevabilité institutionnelle ;
          </li>
          <li>
            Mener des actions de plaidoyer dans le but de rehausser la
            transparence budgétaire.
          </li>
        </ul>
        <h2>Les associations adhérentes à la coalition :</h2>
        <ul>
          <li>
            Transparency Maroc ;
          </li>
          <li>
            Association AL INTILAKA pour le développement, l’environnement et la
            culture ;
          </li>
          <li>
            TAKATOUL Associatif Tanger Métropole ;
          </li>
          <li>
            Réseau marocain des journalistes d&#39;investigation ;
          </li>
          <li>
            Association ISRAR pour l&#39;égalité de l&#39;autonomisation participative ;
          </li>
          <li>
            Association NEAMA pour le développement ;
          </li>
          <li>
            Organisation pour les libertés d&#39;information et d&#39;expression ;
          </li>
          <li>
            Association marocaine de protection des biens publics ;
          </li>
          <li>
            Association Troisième Millénaire pour le développement de l&#39;action ;
          </li>
          <li>
            Observatoire marocain de la recherche et des études sur la société civile et la
démocratie participative ;
          </li>
          <li>
            Association L&#39;Blend ;
          </li>
          <li>
            Association FORUM AL GHAD pour la démocratie et la citoyenneté ;
          </li>
          <li>
            Association TIFLET perspective et de développement ;
          </li>
          <li>
            Association marocaine de l&#39;évaluation ;
          </li>
          <li>
            Espace Associatif pour le Développement Participatif ;
          </li>
          <li>
            Association pour le développement de l&#39;enfance et de la jeunesse ;
          </li>
          <li>
            Initiatives de Formations et d’Accompagnement des Acteurs de Proximité.
(IFAAP)
          </li>
          <li>
            Centre Afaq pour la démocratie citoyenne et participative ;
          </li>
          <li>
            Association Jeunesse Tamdoult pour la Culture et le Développement
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
const playVideo = () => {
  const video = document.querySelector(".coalition__video");
  const playIcon = document.querySelector(".coalition__video-player");
  const overlay = document.querySelector(".coalition__video-overlay");
  video.play();
  video.setAttribute("controls", true);
  playIcon.classList.add("coalition__video-player--hidden");
  overlay.classList.add("coalition__video-overlay--hidden");
};
const pauseVideo = () => {
  const video = document.querySelector(".coalition__video");
  const playIcon = document.querySelector(".coalition__video-player");
  const overlay = document.querySelector(".coalition__video-overlay");
  video.pause();
  video.removeAttribute("controls");
  playIcon.classList.remove("coalition__video-player--hidden");
  overlay.classList.remove("coalition__video-overlay--hidden");
};
</script>

<style lang="scss" scoped>
.coalition {
  @apply py-8 lg:py-12;
  &__container {
    @apply container mx-auto px-4;
  }
  &__content {
    @apply text-center mb-11;
  }
  &__header {
    @apply text-lg font-bold mb-7
      lg:text-xl;
  }
  &__text {
    @apply w-full max-w-5xl mx-auto leading-6
      text-sm sm:text-base lg:text-lg;
  }
  &__video {
    @apply rounded-2xl relative;
    &-container {
      @apply max-w-5xl mx-auto relative;
      &:before {
        content: "";
        @apply absolute w-full h-full p-6 box-content bg-[#eeeceb]
          left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 rounded-2xl;
      }
    }
    &-player {
      @apply absolute z-20
        fill-[white] w-32 h-32
        left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 cursor-pointer;
      &--hidden {
        @apply hidden;
      }
    }
    &-overlay {
      @apply absolute z-10 w-full h-full bg-black/40 inset-0 rounded-2xl;
      &--hidden {
        @apply hidden;
      }
    }
  }
  &__copy {
    @apply text-left mt-10 flex flex-col gap-3 lg:gap-5;
    li{
      @apply w-full max-w-5xl mx-auto leading-6
      text-sm sm:text-base lg:text-lg;
    }
    h2{
      @apply w-full max-w-5xl mx-auto leading-6
      text-sm sm:text-base lg:text-lg
      text-primary font-bold
      mt-5;
    }
    ul{
      @apply list-disc list-inside flex flex-col gap-2;
    }
  }
}
</style>
